var theme = {
  plain: {
    color: "#F8F8F2",
    backgroundColor: "#272822"
  },
  styles: [
    {
      types: ["comment"],
      style: {
        color: "rgb(130, 146, 162)"
      }
    },
    {
      types: ["string"],
      style: {
        color: "rgb(230, 219, 116)"
      }
    },
    {
      types: ["keyword", "logical", "operator", "assignment", "arithmetic"],
      style: {
        color: "rgb(249, 38, 114)"
      }
    },
    {
      types: ["escape", "boolean", "number"],
      style: {
        color: "rgb(174, 129, 255)"
      }
    },
    {
      types: ["function", "class-name"],
      style: {
        color: "rgb(102, 217, 239)"
      }
    },
    {
      types: ["important"],
      style: {
        color: "rgb(253, 151, 31)"
      }
    }
  ]
};

export default theme;
